;
(function(window, document, $, undefined) {
    $(function() {
        // scrollspy
        $('[data-target="section"]').each(function(index, element) {
            var position = $(this).position();
            var $item = $(this);

            // 初始化页面没有滚动时显示的动态效果
            if(position.top <= 100) {
                $item.find('[data-animate="need_animate"]').css('visibility', 'visible');
                $item.addClass('active');
            }


            $item.scrollspy({
                min: position.top - 280,
                max: position.top + $item.height(),
                onEnter: function(element, position) {
                    $item.find('[data-animate="need_animate"]').css('visibility', 'visible');
                    $item.addClass('active');
                },
                onLeave: function(element, position) {}
            });
        });

        var $header = $('#header');
        $('#projectSection').waypoint(function(direction){
            $header.toggleClass('scroll fadeInDown');
        });



        // $(window).trigger('scroll');
        // var anchors = ['project', 'adaptor', 'feature', 'module', 'map'];
        // var colors = ['#008FD4', '#26B1B6', '#5AB65E', '#F67C00', '#DF5048'];
        // var tips = ['首页', '业务范围', '核心优势', '客户案例', '关于我们'];
        // var getSectionSelector = function(index) {
        //     return ['#', anchors[index - 1], 'Section'].join('');
        // };
        // options = {
        //     //Navigation
        //     anchors: anchors,
        //     navigation: true,
        //     navigationPosition: 'right',
        //     navigationTooltips: tips,
        //     //Scrolling
        //     easing: 'easeInOutCubic',
        //     css3: true,
        //     scrollingSpeed: 500,
        //     autoScrolling: true, // 是否自动滑动
        //     //Accessibility
        //     keyboardScrolling: true,
        //     //Design
        //     verticalCentered: false,
        //     resize: false,
        //     // sectionsColor: colors,
        //     paddingTop: '0px',
        //     paddingBottom: '0px',
        //     responsive: true,
        //     //Custom selectors
        //     sectionSelector: '.section',
        //     slideSelector: '.slide',
        //     //events
        //     onLeave: function(index, nextIndex, direction) {
        //         // 无需每次触发动画
        //         // $(getSectionSelector(index)).find('[data-target="need_active"]').removeClass('active');
        //         console.log('onleave');
        //     },
        //     afterLoad: function(anchorLink, index) {
        //         console.log('afterLoad');
        //         $(getSectionSelector(index)).find('[data-target="need_active"]').addClass('active');
        //     },
        //     afterRender: function() {
        //         console.log('afterrender');
        //         $(getSectionSelector(1)).find('[data-target="need_active"]').addClass('active');
        //     },
        //     afterResize: function() {
        //         console.log('afterResize');
        //     },
        //     afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex) {
        //         console.log('afterSlideLoad');
        //     },
        //     onSlideLeave: function(anchorLink, index, slideIndex, direction) {
        //         console.log('onSlideLeave');
        //     }
        // };
        // var fullpage = $('#fullpage').fullpage(options);
        // 动画完成去掉animated样式
        $('.animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
            $(this).removeClass('animated');
        });

        // section2 悬浮处理
        $('#s2Logo').on('mouseenter', '[data-handle="s2_hover"]', function(event) {
            var $item = $(this);
            $item.removeClass($item.data('removeanim'));
            $item.addClass('bounce');
            var target = $item.data('target');
            if ($('#' + target).is(':visible')) {
                return false;
            } else {
                $('.s2-article').css('display','none');
                $('#' + target).fadeIn();
            }
            event.stopPropagation();
        });

        //section2 鼠标移出
        $('#s2Logo').on('mouseleave', '[data-handle="s2_hover"]', function(event) {
            var $item = $(this);
            $item.removeClass('bounce')
            event.stopPropagation();
        });

        var featureSection = $('#featureSection');
        featureSection.on('mouseenter','ul li[data-animate="need_animate"]',function(event){
            var item = $(this);
            var target = $('#' + item.data('text'));
            if(target.is(':visible')){
                return false;
            }else{
                featureSection.find('.s3-text').css('display','none');
                target.fadeIn();
            }
            event.stopPropagation();
        });

        featureSection.on('mouseleave','ul li[data-animate="need_animate"]',function(event){
            var item = $(this);
            event.stopPropagation();
        });
    });
})(window, document, jQuery);

